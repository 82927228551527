// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { compiler } from 'markdown-to-jsx'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import Link from '../components/link'
import '../components/link/style.less'

import flattenGatsbyNode from '../methods/flatten-gatsby-node'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React
const pageSchema = {
  title: 'Audio Talks',
  slug: '/audio-talks',
  abstract: 'Audio Talks',
  breadcrumbs: [
    { title: 'Homepage', slug: '' },
    { title: 'Audio Talks', slug: '/audio-talks' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    _audioTalks: allFile(
      filter: {
        fields: {
          pathAuto: { regex: "/^(?:(/audio-talks/))/" }
          autoNodeType: { eq: "resourceNode" }
          draftAuto: { eq: false }
        }
      }
      sort: { order: ASC, fields: [childResources___title] }
    ) {
      nodes {
        fields {
          pathAuto
        }
        childResources {
          title
          shortAbstract
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => {
  const {
    _audioTalks: { nodes: audioTalksNodes },
  } = props.data
  const audioTalks = map(audioTalksNodes, flattenGatsbyNode)

  return (
    <StandardPageWrapper className="" pageSchema={pageSchema} {...props}>
      <h1 className="flow">Audio Talks</h1>
      <br />
      {map(audioTalks, ({ pathAuto, title, shortAbstract }) => (
        <Link to={pathAuto}>
          <h2>{title}</h2>
          <p className="tj">{compiler(shortAbstract, { wrapper: null })}</p>
          <p className="tj">See more ⟶</p>
          <br />
        </Link>
      ))}
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
